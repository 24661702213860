import { BorrowerPayoutTypeEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutTypeEnum';
import { ApplicationSingleSecurityDepositDto } from 'src/types/api/applications';
import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';
import { TranslatableValueDto } from 'src/types/api/common';
import { StageStatusEnum } from 'src/helpers/Enums/Debt_collection/StageStatusEnum';
import { StageHistoryTypeEnum } from 'src/helpers/Enums/Debt_collection/StageHistoryTypeEnum';

export type DebtCollectionListResponseDto = {
  id: string;
  loan_agreement_number: string;
  owner_name: string;
  manager_name: string;
  debt_amount: number;
  days_late: number;
  notice_1_at: Date | null;
  notice_2_at: Date | null;
  contract_terminated_at: Date | null;
  last_comment_date: Date | null;
  interest_calculation_terminated_at: Date | null;
  last_comment: string | null;
  status: DebtCollectionStatusEnum;
  first_project_name: string;
};

export type DebtCollectionSubRowResponseDto = {
  loan_agreement_annex: string | null;
  annex_date: Date | null;
  project_pid: string;
  debt_amount: number;
  days_late: number;
  interest_rate: number;
  payment_date: Date | null;
  payment_type: BorrowerPayoutTypeEnum | null;
  last_payment_amount: number | null;
  last_payment_date: Date | null;
};

export type DebtCollectionResponseDto = {
  id: string;
  loan_agreement_number: string;
  total_amount: number;
  notice_1_at: Date | null;
  notice_2_at: Date | null;
  contract_terminated_at: Date | null;
  interest_calculation_terminated_at: Date | null;
  owner: StageOwnerResponseDto;
  security_deposits: StageSecurityDeposit;
  borrower_payouts: Array<StageBorrowerPayoutDto>;
  status: StageStatusEnum;
  latest_status_reason: TranslatableValueDto<string> | null;
  calculation_in_progress_at: Date | null;
};

export type StageOwnerResponseDto = {
  id: string;
  name: string | null;
  pid: string | null;
  type: string;
  legal_code: string | null;
  vat_code: string | null;
  country: string | null;
  email: string | null;
  phone: string | null;
  last_login_at: Date | null;
  suspended_at: Date | null;
  suspension_reason: string | null;
  created_at: Date;
  verified: boolean;
  executive_first_name: string | null;
  executive_last_name: string | null;
  executive_address_house_number: string | null;
  executive_address_street: string | null;
  executive_address_city: string | null;
  executive_address_country: string | null;
  executive_phone: string | null;
  executive_email: string | null;
  executive_gov_code: string | null;
  executive_gov_document_type: string | null;
  executive_gov_document_number: string | null;
  executive_gov_document_issue_country: string | null;
  executive_gov_document_expiration_date: string | null;
};

export type StageSecurityDeposit = {
  security_deposits: Array<ApplicationSingleSecurityDepositDto>;
  history_security_deposits: ApplicationSingleSecurityDepositDto[];
  info: StageSecurityDepositInfoDto;
  security_deposits_includes_vat: boolean | null;
};

export type StoreStageSecurityDepositsRequestDto = {
  security_deposits: Array<ApplicationSingleSecurityDepositDto>;
};

export type StageSecurityDepositInfoDto = {
  surety_exists: boolean | null;
  surety_name: string | null;
  surety_code: string | null;
  subordination_amount: number | null;
  security_deposits_includes_vat: boolean | null;

  security_measures: string | null;
  hypothec_number: string | null;
  security_deposit_appraisers: string | null;
  security_deposit_value: number | null;
};

export type StageBorrowerPayoutDto = {
  id: string;
  project_pid: string;
  status: BorrowerPayoutStatusEnum;
  type: BorrowerPayoutTypeEnum;
  borrower_total: number;
  profitus_total: number;
  created_at: Date;
  payment_date: Date;
  loan_repayment: number;
  fine_on_pre_return: number;
  debt_fee: number;
  total_basic_interest: number;
  additional_interest_investment: number;
  additional_interest_profitus: number;
};

export type CommentResponseDto = {
  id: string;
  stage_id: string;
  author_id: string;
  author_name: string;
  comment: string;
  created_at: Date;
  updated_at: Date;
};

export type EventUpdateResponseDto = {
  id: string;
  stage_id: string;
  author_id: string;
  author_name: string;
  comment: string;
  created_at: Date;
  updated_at: Date;
};

export type StageHistoryResponseDto = {
  id: string;
  stage_id: string;
  author_id: string;
  author_name: string;
  comment: string;
  private_data: string;
  type: StageHistoryTypeEnum;
  created_at: Date;
  updated_at: Date;
};

export type StoreCommentRequestDto = {
  stage_id: string;
  comment: string;
};

export type StoreEventUpdateRequestDto = {
  stage_id: string;
  comment: string;
};

export type StageStatusChangeRequestDto = {
  status: StageStatusEnum;
  purpose: TranslatableValueDto<string>;
  contract_terminated_at: Date;
  interest_calculation_terminated_at: Date | null;
};

export type UpdateREDNoticeDateRequestDto = {
  notice_date: Date | string;
  key: string;
};

export enum DebtCollectionStatusEnum {
  INIT = 'init',
  LATE = 'late',
  RECOVERING = 'recovering',
  OTHER = 'other',
  FINISHED = 'finished',
}
