import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { ReviewResponseDto } from 'src/types/api/landingPage/reviews';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const Reviews: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<ReviewResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<ReviewResponseDto>[] | any>(
    () => [
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<ReviewResponseDto>) => (
          <div>{t('common.datetime', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.email'),
        accessor: 'email',
        disableSortBy: true,
        width: 150,
        Cell: (cell: Cell<ReviewResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.email}</span>;
        },
      },
      {
        Header: t('table.review'),
        accessor: 'review',
        disableSortBy: true,
        width: 500,
        Cell: (cell: Cell<ReviewResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.review}</span>;
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.reviews.fetchFilteredReviews(request).then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.reviews')}</h4>
            <HiddenColumnsProvider title={'ReviewsIndex'}>
              <Table
                title={'ReviewsIndex'}
                onFetchData={fetchData}
                initialSortBy={INITIAL_SORT_ORDER}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                truncateCells={false}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Reviews;
