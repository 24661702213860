import React, { useCallback, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import MetisMenu from 'metismenujs';
import { useTranslation } from 'react-i18next';

import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import usePermissions from 'src/helpers/usePermissions';
import { BorrowerPayoutsRouteTranslationMap } from 'src/helpers/maps/PaymentMaps';

const SidebarContent: React.FC<any> = () => {
  const ref = useRef<SimpleBar>();
  const { t } = useTranslation();
  const p = usePermissions();

  const location = useLocation();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = window.location.pathname;

    new MetisMenu('#side-menu');
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    if (!ul) return;
    const items = ul.getElementsByTagName('a');

    function getPosition(string: string, subString: string, index: number) {
      return string.split(subString, index).join(subString).length;
    }

    // @TIP - some dropdowns are > 2 levels deep
    // in those cases write down root pathnames and add them to this array
    // this helps to keep open side menu if you go inside 3 lever nest item card
    const threeLevelDropdowns = ['/borrower-payouts'];

    const mainPathEndPos = getPosition(pathName, '/', 2);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      } else {
        let tempPathName = pathName.substring(0, mainPathEndPos);
        const tempPathEndPos = getPosition(pathName, '/', 3);

        if (threeLevelDropdowns.includes(tempPathName)) {
          tempPathName = pathName.substring(0, tempPathEndPos);
        }

        if (tempPathName === items[i].pathname) {
          matchingMenuItem = items[i];
        }
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref.current) {
      ref.current.recalculate();
    }
  }, []);

  function scrollElement(item: HTMLElement) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight && ref.current) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: '100%' }}
        ref={ref as React.LegacyRef<SimpleBar>}
        className="sidebar-menu-scroll"
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{t('menu.title')} </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className={'fa fa-fw fa-home me-2 font-size-18 text-center'} />
                <span>{t('menu.dashboard')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={RouteList.DASHBOARD.DASHBOARD.LIST} className="waves-effect">
                    <i className="fa fa-home me-2 font-size-18" />
                    <span>{t('menu.dashboard')}</span>
                  </Link>
                </li>
                <PermissionAction permissions={Permissions.BO__DASHBOARD__MARKETING_DATA}>
                  <li>
                    <Link to={RouteList.DASHBOARD.MARKETING.LIST} className="waves-effect">
                      <i className="fas fa-money-bill-wave-alt me-2 font-size-18" />
                      <span>{t('menu.dashboard.marketing')}</span>
                    </Link>
                  </li>
                </PermissionAction>
                <PermissionAction permissions={Permissions.BO__DASHBOARD__WALLETS_DATA}>
                  <li>
                    <Link to={RouteList.DASHBOARD.WALLET_INFO.LIST} className="waves-effect">
                      <i className="fas fa-money-bill-wave-alt me-2 font-size-18" />
                      <span>{t('menu.dashboard.wallets_info')}</span>
                    </Link>
                  </li>
                </PermissionAction>
                <PermissionAction permissions={Permissions.BO__DASHBOARD__PORTFOLIO_DATA}>
                  <li>
                    <Link to={RouteList.DASHBOARD.PORTFOLIO_INFO.LIST} className="waves-effect">
                      <i className="fa fa-home me-2 font-size-18" />
                      <span>{t('menu.portfolio')}</span>
                    </Link>
                  </li>
                </PermissionAction>
                <PermissionAction permissions={Permissions.BO__DASHBOARD__PORTFOLIO_DATA}>
                  <li>
                    <Link to={RouteList.DASHBOARD.INVESTMENTS.LIST} className="waves-effect">
                      <i className="fa fa-chart-line me-2 font-size-18" />
                      <span>{t('menu.dashboard.investments')}</span>
                    </Link>
                  </li>
                </PermissionAction>
              </ul>
            </li>
            <PermissionAction permissions={Permissions.BO__APPLICATIONS__FILTER}>
              <li>
                <Link to={RouteList.APPLICATION.LIST} className="waves-effect">
                  <i className="far fa-clipboard me-2 font-size-18 text-center" />
                  <span>{t('menu.applications')}</span>
                </Link>
              </li>
            </PermissionAction>
            <PermissionAction permissions={Permissions.BO__PROJECTS__FILTER}>
              <li>
                <Link to={RouteList.PROJECTS.LIST} className="waves-effect">
                  <i className="fa fa-handshake me-2 font-size-18" />
                  <span>{t('menu.projects')}</span>
                </Link>
              </li>
            </PermissionAction>
            <PermissionAction permissions={Permissions.BO__DOCUMENT_TEMPLATES__LIST}>
              <li>
                <Link to={RouteList.DOCUMENT_TEMPLATES.LIST} className="waves-effect">
                  <i className="far fa-file me-2 font-size-18 text-center" />
                  <span>{t('menu.document_templates')}</span>
                </Link>
              </li>
            </PermissionAction>
            <PermissionAction permissions={Permissions.BO__REPORTS__VIEW}>
              <li>
                <Link to={RouteList.REPORTS.VIEW} className="waves-effect">
                  <i className="fas fa-file me-2 font-size-18 text-center" />
                  <span>{t('menu.reports')}</span>
                </Link>
              </li>
            </PermissionAction>
            <PermissionAction permissions={[Permissions.BO__USERS__FILTER]}>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-user me-2 font-size-18 text-center'} />
                  <span>{t('menu.users')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__ROLES__FILTER}>
                    <li>
                      <Link to={RouteList.ROLES.MATRIX} className="waves-effect">
                        <i className="fas fa-table me-2 font-size-18" />
                        <span>{t('menu.role_matrix')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={RouteList.ROLES.LIST} className="waves-effect">
                        <i className="fas fa-lock me-2 font-size-18" />
                        <span>{t('menu.roles')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__USERS__FILTER}>
                    <li>
                      <Link to={RouteList.USERS.LIST} className="waves-effect">
                        <i className="fas fa-user me-2 font-size-18" />
                        <span>{t('menu.users')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__USERS__ADMIN_FILTER}>
                    <li>
                      <Link to={RouteList.USERS.ADMIN_LIST} className="waves-effect">
                        <i className="fas fa-user-shield me-2 font-size-18" />
                        <span>{t('menu.admins')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                </ul>
              </li>
            </PermissionAction>
            <PermissionAction permissions={Permissions.BO__LOCALE__TRANSLATIONS__FILTER}>
              <li>
                <Link to={RouteList.LOCALE.LIST} className="waves-effect">
                  <i className="fas fa-language me-2 font-size-18 text-center" />
                  <span>{t('menu.translations')}</span>
                </Link>
              </li>
            </PermissionAction>
            <PermissionAction permissions={Permissions.BO__BONUS_SYSTEM__LIST}>
              <li>
                <Link to={RouteList.BONUS_SYSTEM.LIST} className="waves-effect">
                  <i className="fas fa-gift me-2 font-size-18 text-center" />
                  <span>{t('menu.bonus_system')}</span>
                </Link>
              </li>
            </PermissionAction>
            {p.hasAny([
              Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__LIST,
              Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__LIST,
              Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__ESTIMATE__PAYOUT,
              Permissions.BO__PAYMENTS__INVOICES__LIST,
              Permissions.BO__PAYMENTS__FROZEN_DEVELOPER_PAYOUTS__LIST,
              Permissions.BO__PAYMENTS__MANUAL_WITHDRAWALS__LIST,
            ]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-funnel-dollar me-2 font-size-18 text-center'} />
                  <span>{t('menu.payments')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__LIST}>
                    <li>
                      <Link to={RouteList.PAYMENTS.PROJECT_PAYOUTS.LIST} className="waves-effect">
                        <i className="fas fa-funnel-dollar me-2 font-size-18 text-center" />
                        <span>{t('menu.payments.project_payouts')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction
                    permissions={Permissions.BO__PAYMENTS__FROZEN_DEVELOPER_PAYOUTS__LIST}
                  >
                    <li>
                      <Link
                        to={RouteList.PAYMENTS.FROZEN_DEVELOPER_PAYOUTS.LIST}
                        className="waves-effect"
                      >
                        <i className="fas fa-file-invoice me-2 font-size-18 text-center" />
                        <span>{t('menu.payments.frozen_developer_payouts')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__LIST}>
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-hand-holding-usd me-2 font-size-18 text-center" />
                        <span>{t('menu.payments.borrower_payouts')}</span>
                      </Link>
                      <ul className="sub-menu">
                        {Object.values(RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST).map(
                          (route, routeIndex) => (
                            <li key={`borrower-payouts-list${routeIndex}`}>
                              <Link to={route} className="waves-effect">
                                <i className="fas fa-filter me-2 font-size-18 text-center" />
                                <span>{t(BorrowerPayoutsRouteTranslationMap[route])}</span>
                              </Link>
                            </li>
                          ),
                        )}
                      </ul>
                    </li>
                  </PermissionAction>
                  <PermissionAction
                    permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__ESTIMATE__PAYOUT}
                  >
                    <li>
                      <Link
                        to={RouteList.PAYMENTS.BORROWER_PAYOUTS.ESTIMATES}
                        className="waves-effect"
                      >
                        <i className="fas fa-file-invoice me-2 font-size-18 text-center" />
                        <span>{t('menu.payments.estimates')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__PAYMENTS__INVOICES__LIST}>
                    <li>
                      <Link to={RouteList.PAYMENTS.INVOICES.LIST} className="waves-effect">
                        <i className="fas fa-file-invoice me-2 font-size-18 text-center" />
                        <span>{t('menu.payments.invoices')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction
                    permissions={Permissions.BO__PAYMENTS__MANUAL_WITHDRAWALS__LIST}
                  >
                    <li>
                      <Link
                        to={RouteList.PAYMENTS.MANUAL_WITHDRAWALS.LIST}
                        className="waves-effect"
                      >
                        <i className="fas fa-money-bill-wave-alt me-2 font-size-18 text-center" />
                        <span>{t('menu.payments.manual_withdrawals')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__LIST}>
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-exclamation-triangle me-2 font-size-18 text-center" />
                        <span>{t('payments.failed_payouts')}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={RouteList.PAYMENTS.FAILED_INVESTMENT_PAYOUTS.LIST}>
                            <i className="fas fa-hand-holding-usd me-1 text-center" />
                            <span className="font-size-12">{t('payments.investment_payouts')}</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={RouteList.PAYMENTS.FAILED_INVESTMENT_PAYOUTS.MIGRATED_ACCOUNTS.LIST}
                          >
                            <i className="fas fa-user-check me-1 text-center" />
                            <span className="font-size-12">{t('payments.migrated_accounts')}</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </PermissionAction>
                </ul>
              </li>
            )}
            <PermissionAction
              permissions={[
                Permissions.BO__OPERATIONS__P2P__LIST,
                Permissions.BO__OPERATIONS__MONEY__LIST,
              ]}
            >
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-user me-2 font-size-18 text-center'} />
                  <span>{t('menu.operations')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__OPERATIONS__P2P__LIST}>
                    <li>
                      <Link to={RouteList.OPERATIONS.P2P.LIST} className="waves-effect">
                        <i className="fas fa-exchange-alt me-2 font-size-18 text-center" />
                        <span>{t('menu.wallet_p2p_operations')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__OPERATIONS__MONEY__LIST}>
                    <li>
                      <Link to={RouteList.OPERATIONS.MONEY.LIST} className="waves-effect">
                        <i className="fas fa-exchange-alt me-2 font-size-18 text-center" />
                        <span>{t('menu.wallet_money_operations')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                </ul>
              </li>
            </PermissionAction>
            <PermissionAction permissions={[Permissions.BO__DEBT_COLLECTION__LIST]}>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-user me-2 font-size-18 text-center'} />
                  <span>{t('menu.debt_collection')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__DEBT_COLLECTION__LIST}>
                    <li>
                      <Link to={RouteList.DEBT_COLLECTION.LIST} className="waves-effect">
                        <i className="fas fa-exchange-alt me-2 font-size-18 text-center" />
                        <span>{t('menu.debt_collection')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                </ul>
              </li>
            </PermissionAction>
            <PermissionAction permissions={Permissions.BO__AUTO_INVESTMENT__VIEW}>
              <li>
                <Link to={RouteList.AUTO_INVESTMENT.VIEW} className="waves-effect">
                  <i className="fas fa-file-invoice-dollar me-2 font-size-18 text-center" />
                  <span>{t('menu.auto_investment')}</span>
                </Link>
              </li>
            </PermissionAction>
            {p.hasAny([
              Permissions.BO__COMMUNICATION__MANAGEMENT__VIEW,
              Permissions.BO__COMMUNICATION__CUSTOM_HISTORY__VIEW,
              Permissions.BO__COMMUNICATION__AUTO_HISTORY__VIEW,
            ]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-user me-2 font-size-18 text-center'} />
                  <span>{t('menu.communication')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__COMMUNICATION__MANAGEMENT__VIEW}>
                    <li>
                      <Link to={RouteList.COMMUNICATION.MANAGEMENT} className="waves-effect">
                        <i className="fas fa-table me-2 font-size-18" />
                        <span>{t('menu.management')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction
                    permissions={Permissions.BO__COMMUNICATION__CUSTOM_HISTORY__VIEW}
                  >
                    <li>
                      <Link to={RouteList.COMMUNICATION.CUSTOM_HISTORY} className="waves-effect">
                        <i className="fas fa-table me-2 font-size-18" />
                        <span>{t('menu.custom_history')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__COMMUNICATION__AUTO_HISTORY__VIEW}>
                    <li>
                      <Link to={RouteList.COMMUNICATION.AUTO_HISTORY} className="waves-effect">
                        <i className="fas fa-table me-2 font-size-18" />
                        <span>{t('menu.auto_history')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className={'fas fa-mail-bulk me-2 font-size-18 text-center'} />
                      <span>{t('menu.custom_emails')}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <PermissionAction
                          permissions={Permissions.BO__COMMUNICATION__MANAGEMENT__VIEW}
                        >
                          <Link
                            to={RouteList.COMMUNICATION.CUSTOM_EMAIL.CREATE}
                            className="waves-effect"
                          >
                            <i className="fas fa-inbox me-2 font-size-18" />
                            <span className="font-size-12">{t('menu.import_list')}</span>
                          </Link>
                        </PermissionAction>
                      </li>
                      <li>
                        <PermissionAction
                          permissions={Permissions.BO__COMMUNICATION__MANAGEMENT__VIEW}
                        >
                          <Link
                            to={RouteList.COMMUNICATION.CUSTOM_EMAIL.LIST}
                            className="waves-effect"
                          >
                            <i className="fas fa-history me-2 font-size-18" />
                            <span className="font-size-12">{t('menu.history')}</span>
                          </Link>
                        </PermissionAction>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            )}
            {p.hasAny([
              Permissions.BO__LANDING_PAGE__INQUIRIES__VIEW,
              Permissions.BO__LANDING_PAGE__NEWS__VIEW,
              Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__VIEW,
              Permissions.BO__LANDING_PAGE__JOB_POSITIONS__VIEW,
              Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__VIEW,
              Permissions.BO__LANDING_PAGE__FAQ__VIEW,
              Permissions.BO__LANDING_PAGE__TRANSLATIONS__VIEW,
              Permissions.BO__LANDING_PAGE__REVIEWS__VIEW,
            ]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-house-user me-2 font-size-18 text-center'} />
                  <span>{t('menu.landing_page')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__INQUIRIES__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.INQUIRIES.LIST} className="waves-effect">
                        <i className="fas fa-clipboard me-2 font-size-18" />
                        <span>{t('menu.landing_page.inquiries')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__NEWS__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.NEWS.LIST} className="waves-effect">
                        <i className="fas fa-newspaper me-2 font-size-18" />
                        <span>{t('menu.landing_page.news')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.EXTERNAL_NEWS.LIST} className="waves-effect">
                        <i className="far fa-newspaper me-2 font-size-18" />
                        <span>{t('menu.landing_page.external_news')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__JOB_POSITIONS__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.JOB_POSITIONS.LIST} className="waves-effect">
                        <i className="fas fa-briefcase me-2 font-size-18" />
                        <span>{t('menu.landing_page.job_positions')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST} className="waves-effect">
                        <i className="fas fa-users me-2 font-size-18" />
                        <span>{t('menu.landing_page.team_members')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__FAQ__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.FAQ.LIST} className="waves-effect">
                        <i className="fas fa-question me-2 font-size-18" />
                        <span>{t('menu.landing_page.faq')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__TRANSLATIONS__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.TRANSLATIONS} className="waves-effect">
                        <i className="fas fa-language me-2 font-size-18" />
                        <span>{t('menu.landing_page.translations')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__SEO__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.SEO.LIST} className="waves-effect">
                        <i className="fas fa-search me-2 font-size-18" />
                        <span>{t('menu.landing_page.seo')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__REVIEWS__VIEW}>
                    <li>
                      <Link to={RouteList.LANDING_PAGE.REVIEWS} className="waves-effect">
                        <i className="fas fa-vote-yea me-2 font-size-18" />
                        <span>{t('menu.landing_page.reviews')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                </ul>
              </li>
            )}
            <PermissionAction permissions={[Permissions.BO__AML_MANAGEMENT__LIST]}>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-user-shield me-2 font-size-18 text-center'} />
                  <span>{t('menu.aml_management')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__AML_MANAGEMENT__LIST}>
                    <li>
                      <Link to={RouteList.AML_MANAGEMENT.RISK_LEVEL} className="waves-effect">
                        <i className="fas fa-list me-2 font-size-18 text-center" />
                        <span>{t('menu.aml_management.risk_level')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__AML_MANAGEMENT__LIST}>
                    <li>
                      <Link to={RouteList.AML_MANAGEMENT.FROZEN_STATUS} className="waves-effect">
                        <i className="fas fa-list-alt me-2 font-size-18 text-center" />
                        <span>{t('menu.aml_management.frozen_status')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__AML_MANAGEMENT__LIST}>
                    <li>
                      <Link to={RouteList.AML_MANAGEMENT.ISSUES} className="waves-effect">
                        <i className="fas fa-exclamation me-2 font-size-18 text-center" />
                        <span>{t('menu.aml_management.issues')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                  <PermissionAction permissions={Permissions.BO__AML_MANAGEMENT__LIST}>
                    <li>
                      <Link
                        to={RouteList.AML_MANAGEMENT.RESIDENCE_COUNTRY}
                        className="waves-effect"
                      >
                        <i className="fas fa-exclamation me-2 font-size-18 text-center" />
                        <span>{t('menu.aml_management.residence_country')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                </ul>
              </li>
            </PermissionAction>
            <PermissionAction permissions={Permissions.BO__PROSPECT_APPLICATIONS__FILTER}>
              <li>
                <Link to={RouteList.PROSPECT_APPLICATION.LIST} className="waves-effect">
                  <i className="far fa-address-card me-2 font-size-18 text-center" />
                  <span>{t('menu.prospect_applications')}</span>
                </Link>
              </li>
            </PermissionAction>
            <PermissionAction permissions={[Permissions.BO__FORECAST__LIST]}>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className={'fas fa-wave-square me-2 font-size-18 text-center'} />
                  <span>{t('menu.forecast')}</span>
                </Link>
                <ul className="sub-menu">
                  <PermissionAction permissions={Permissions.BO__FORECAST__PRICING_TOOL}>
                    <li>
                      <Link to={RouteList.FORECAST.PRICING_TOOL} className="waves-effect">
                        <i className="fas fa-calculator me-2 font-size-18 text-center" />
                        <span>{t('menu.forecast.pricing_tool')}</span>
                      </Link>
                    </li>
                  </PermissionAction>
                </ul>
              </li>
            </PermissionAction>
            <PermissionAction permissions={[Permissions.BO__RC_REPORTS_LIST]}>
              <li>
                <Link to={RouteList.RC.LIST} className="waves-effect">
                  <i className="fas fa-file me-2 font-size-18 text-center" />
                  <span>{t('menu.rc')}</span>
                </Link>
              </li>
            </PermissionAction>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
