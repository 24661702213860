export const RouteList = {
  HOME: '/',
  NOT_FOUND: '/404',

  DASHBOARD: {
    DASHBOARD: {
      LIST: '/dashboard',
    },
    WALLET_INFO: {
      LIST: '/dashboard/wallet-info',
    },
    MARKETING: {
      LIST: '/dashboard/marketing',
    },
    PORTFOLIO_INFO: {
      LIST: '/dashboard/portfolio-info',
    },
    INVESTMENTS: {
      LIST: '/dashboard/investments',
    },
  },

  PROFILE: {
    PROFILE: '/profile',
  },

  APPLICATION: {
    LIST: '/applications',
    VIEW: '/applications/application/:applicationId',
  },

  PAYMENTS: {
    PROJECT_PAYOUTS: {
      LIST: '/project-payouts',
      VIEW: '/project-payouts/project/:projectId',
    },
    FROZEN_DEVELOPER_PAYOUTS: {
      LIST: '/frozen-developer-payouts',
    },
    BORROWER_PAYOUTS: {
      LIST: {
        ALL: '/borrower-payouts/all',
        INIT: '/borrower-payouts/init',
        // NEW: '/borrower-payouts/new', // @TIP - disabled as not used?
        DRAFT: '/borrower-payouts/draft',
        SCHEDULED: '/borrower-payouts/scheduled',
        PENDING: '/borrower-payouts/pending',
        RECEIVED: '/borrower-payouts/received',
        // PERMISSION_TO_SELL: '/borrower-payouts/permission-to-sell', // @TIP - disabled as not used?
        PAID: '/borrower-payouts/paid',
      },
      VIEW: '/borrower-payouts/:status/borrower-payout/:borrowerPayoutId',
      CREATE_PARTIAL_RETURN: '/borrower-payouts/create-partial-return',
      ESTIMATES: '/borrower-payouts/estimate-payout',
    },
    INVOICES: {
      LIST: '/invoices',
      CREATE: '/invoices/new',
    },
    MANUAL_WITHDRAWALS: {
      LIST: '/manual-withdrawals',
    },
    FAILED_INVESTMENT_PAYOUTS: {
      LIST: '/failed-investment-payouts',
      MIGRATED_ACCOUNTS: {
        LIST: '/failed-investment-payouts/migrated-accounts',
      },
    },
  },

  IMPORTS: {
    VIEW: '/imports/:importId',
  },

  USERS: {
    LIST: '/users',
    ADMIN_LIST: '/admins',
    VIEW_COMPANY: '/users/company/:companyId',
    VIEW_USER: '/users/user/:userId',
  },

  PROJECTS: {
    LIST: '/projects',
    VIEW_PROJECT: '/projects/project/:projectId',
  },

  OPERATIONS: {
    P2P: {
      LIST: '/operations/p2p',
    },
    MONEY: {
      LIST: '/operations/money',
    },
  },

  DEBT_COLLECTION: {
    LIST: '/debt-collections',
    VIEW: '/debt-collections/debt-collection/:stageId',
    DOCUMENTS: '/debt-collections/documents',
  },

  AUTHENTICATION: {
    LOGIN: '/login',
    RESET_PASSWORD: '/reset-password',
    CONFIRM_RESET_PASSWORD: '/reset-password/:token',
  },

  ROLES: {
    MATRIX: '/roles/matrix',
    LIST: '/roles',
    CREATE: '/roles/new',
    UPDATE: '/roles/role/:roleId',
  },
  LOCALE: {
    LIST: '/locale',
  },
  DOCUMENT_TEMPLATES: {
    LIST: '/document-templates',
    VIEW: '/document-templates/document-template/:documentTemplateId',
  },
  REPORTS: {
    VIEW: '/reports',
  },
  BONUS_SYSTEM: {
    LIST: '/bonus-system',
    CREATE: '/bonus-system/new',
    UPDATE: '/bonus-system/:bonusId',
  },
  AUTO_INVESTMENT: {
    VIEW: '/auto-investment',
  },
  COMMUNICATION: {
    MANAGEMENT: '/management',
    CUSTOM_HISTORY: '/custom-history',
    AUTO_HISTORY: '/auto-history',
    CUSTOM_EMAIL: {
      CREATE: '/custom-email/new',
      LIST: '/custom-email',
    },
  },
  LANDING_PAGE: {
    INQUIRIES: {
      LIST: '/landing-page/inquiries',
      VIEW: '/landing-page/inquiries/inquiry/:inquiryId',
    },
    NEWS: {
      LIST: '/landing-page/news',
      CREATE: '/landing-page/news/new',
      UPDATE: '/landing-page/news/:newsId',
    },
    EXTERNAL_NEWS: {
      LIST: '/landing-page/external-news',
      CREATE: '/landing-page/external-news/new',
      UPDATE: '/landing-page/external-news/:externalNewsId',
    },
    JOB_POSITIONS: {
      LIST: '/landing-page/job-positions',
      CREATE: '/landing-page/job-positions/new',
      UPDATE: '/landing-page/job-positions/:jobPositionId',
    },
    TEAM_MEMBERS: {
      LIST: '/landing-page/team-members',
      CREATE: '/landing-page/team-members/new',
      UPDATE: '/landing-page/team-members/:teamMemberId',
    },
    FAQ: {
      LIST: '/landing-page/faq',
      CREATE: '/landing-page/faq/new',
      UPDATE: '/landing-page/faq/:faqId',
    },
    TRANSLATIONS: '/landing-page/translations',
    SEO: {
      LIST: '/landing-page/seo',
      UPDATE: '/landing-page/seo/:landingPageId',
    },
    REVIEWS: '/landing-page/reviews',
  },
  AML_MANAGEMENT: {
    RISK_LEVEL: '/aml-management/risk-level',
    FROZEN_STATUS: '/aml-management/frozen-status',
    ISSUES: '/aml-management/issues',
    RESIDENCE_COUNTRY: '/aml-management/residence-country',
  },
  FORECAST: {
    PRICING_TOOL: '/forecast/pricing-tool',
  },
  NOTIFICATIONS: {
    LIST: '/notifications',
  },
  // Registru centras
  RC: {
    LIST: '/rc',
  },
  PROSPECT_APPLICATION: {
    LIST: '/prospect-applications',
    VIEW: '/propspect-applications/prospect-aplication/:prospectApplicationId',
  },
};
