import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { ReviewResponseDto } from 'src/types/api/landingPage/reviews';

class Reviews extends Client {
  api = {
    FETCH_REVIEWS: `${this.http.baseUrl}/reviews/filter`,
  };

  fetchFilteredReviews = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ReviewResponseDto>> => {
    return this.http.get(this.api.FETCH_REVIEWS, { params: request });
  };
}

export default Reviews;
