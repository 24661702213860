import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Cell } from 'react-table';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import IconArrowDown from 'src/components/Table/AdvanceFilters/Icons/IconArrowDown';
import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { BorrowerPayoutContextState, withBorrowerPayout } from '../BorrowerPayoutContext';
import {
  BorrowerPayoutListResponseDto,
  InvestmentPayoutListResponseDto,
} from 'src/types/api/payments/borrowerPayouts';
import Table, { Column } from 'src/components/Table';
import ColumnFilter from 'src/components/Table/Filters/ColumnFilter';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { InvestmentPayoutStatusEnum } from 'src/helpers/Enums/Payments/InvestmentPayoutStatusEnum';
import { InvestmentPayoutTypeEnum } from 'src/helpers/Enums/Payments/InvestmentPayoutTypeEnum';
import InvestmentPayoutType from 'src/components/Projects/InvestmentPayoutType';
import InvestmentPayoutStatus from 'src/components/Projects/InvestmentPayoutStatus';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import PermissionAction from 'src/components/PermissionAction';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import WalletProviderBadge from 'src/components/Badges/WalletProviderBadge';
import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';

const INITIAL_SORT_ORDER = [
  {
    id: 'paid_at',
    desc: false,
  },
];

const TabInvestmentPayouts: React.FC<BorrowerPayoutContextState> = ({
  borrowerPayout,
  setBorrowerPayout,
}) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { borrowerPayoutId } = useParams<{ borrowerPayoutId: string }>();
  const [data, setData] = useState<PaginationData<InvestmentPayoutListResponseDto>>();

  const confirmInvestmentPayout = useCallback(
    (cell: Cell<InvestmentPayoutListResponseDto> | any) => {
      showModal(
        <ActionModal
          title={t('payments.investment_payouts.confirm.title')}
          body={t('payments.investment_payouts.confirm.body')}
          successMessage={t('payments.investment_payouts.confirm.success')}
          actionText={t('payments.investment_payouts.confirm')}
          onAction={async () => {
            return Api.payments.borrowerPayouts
              .confirmInvestmentPayout(borrowerPayoutId, cell.row.original.id)
              .then((response) => setBorrowerPayout(response))
              .finally(() => cell.dispatch({ type: 'refreshData' }));
          }}
        />,
      );
    },
    [borrowerPayoutId, setBorrowerPayout, showModal, t],
  );

  const retryInvestmentPayout = useCallback(
    (cell: Cell<InvestmentPayoutListResponseDto> | any) => {
      showModal(
        <ActionModal
          title={t('payments.investment_payouts.retry.title')}
          body={t('payments.investment_payouts.retry.body')}
          successMessage={t('payments.investment_payouts.retry.success')}
          actionText={t('payments.investment_payouts.retry')}
          onAction={async () => {
            return Api.payments.borrowerPayouts
              .retryInvestmentPayout(borrowerPayoutId, cell.row.original.id)
              .then((response) => setBorrowerPayout(response))
              .finally(() => cell.dispatch({ type: 'refreshData' }));
          }}
        />,
      );
    },
    [borrowerPayoutId, setBorrowerPayout, showModal, t],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.payments.borrowerPayouts
        .fetchBorrowerInvestmentPayouts(borrowerPayoutId, request)
        .then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [borrowerPayoutId, borrowerPayout],
  );

  const columns = useMemo<Column<InvestmentPayoutListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
        width: 200,
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <InvestmentPayoutStatus value={cell.value} />
        ),
        Filter: ColumnFilter,
        filterProps: {
          options: getEnumFilterSelectValues(
            InvestmentPayoutStatusEnum,
            'payments.investment_payouts.status',
          ),
        },
      },
      {
        Header: t('table.investor_pid'),
        accessor: 'investor_pid',
        sortType: 'string',
        width: 150,
      },
      {
        Header: t('table.investor_name'),
        accessor: 'investor_name',
        sortType: 'string',
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        width: 150,
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <InvestmentPayoutType value={cell.value} />
        ),
        Filter: ColumnFilter,
        filterProps: {
          options: getEnumFilterSelectValues(
            InvestmentPayoutTypeEnum,
            'payments.investment_payouts.type',
          ),
        },
      },
      {
        Header: t('label.wallet_provider'),
        accessor: 'wallet_provider',
        width: 100,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <WalletProviderBadge value={cell.value} />
        ),
      },
      {
        Header: t('table.interest_total'),
        accessor: 'interest_total',
        sortType: 'string',
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <div>
            <div className="d-flex align-items-center justify-content-between mb-1">
              {cell.value !== null ? t('common.money', { value: cell.value }) : '-'}{' '}
              <Button id={`interest_total_${cell.row.id}`} size="sm">
                <IconArrowDown />
              </Button>
            </div>

            <UncontrolledCollapse toggler={`#interest_total_${cell.row.id}`}>
              <Card>
                <CardBody className="p-1">
                  {t('label.borrower_interest')}:
                  {' ' +
                    t('common.money', {
                      value: cell.row.original.rounded_total_interest_from_borrower_no_gpm,
                    })}
                  <br />
                  {t('label.profitus_interest')}:
                  {' ' +
                    t('common.money', {
                      value: cell.row.original.rounded_total_interest_from_profitus_no_gpm,
                    })}
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        ),
      },
      {
        Header: t('table.gpm_total'),
        accessor: 'gpm_total',
        sortType: 'string',
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <div>
            <div className="d-flex align-items-center justify-content-between mb-1">
              {cell.value !== null ? t('common.money', { value: cell.value }) : '-'}{' '}
              <Button id={`gpm_total_${cell.row.id}`} size="sm">
                <IconArrowDown />
              </Button>
            </div>

            <UncontrolledCollapse toggler={`#gpm_total_${cell.row.id}`}>
              <Card>
                <CardBody className="p-1">
                  {t('label.borrower_interest')}:
                  {' ' +
                    t('common.money', {
                      value: cell.row.original.rounded_total_interest_from_borrower_only_gpm,
                    })}
                  <br />
                  {t('label.profitus_interest')}:
                  {' ' +
                    t('common.money', {
                      value: cell.row.original.rounded_total_interest_from_profitus_only_gpm,
                    })}
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        ),
      },
      {
        Header: t('table.loan_repayment'),
        accessor: 'loan_repayment',
        sortType: 'string',
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.transferred_amount'),
        accessor: 'transferred_amount',
        sortType: 'string',
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.payment_date'),
        accessor: 'payment_date',
        sortType: 'string',
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <div>{t('common.date', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.paid_at'),
        accessor: 'paid_at',
        sortType: 'string',
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 200,
        Cell: (cell: Cell<InvestmentPayoutListResponseDto>) => {
          return (
            <div className={'actions d-flex gap-2'}>
              {borrowerPayout?.status === BorrowerPayoutStatusEnum.PAID &&
                cell.row.original.status !== InvestmentPayoutStatusEnum.PAID && (
                  <>
                    <PermissionAction
                      permissions={
                        Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__CONFIRM_INVESTMENT_PAYOUT
                      }
                    >
                      <PrimaryButton
                        title={t('payments.investment_payouts.confirm.title')}
                        onClick={() => confirmInvestmentPayout(cell)}
                        btype={'btn-primary btn-sm'}
                      />
                    </PermissionAction>
                    <PermissionAction
                      permissions={
                        Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__RETRY_FAILED_INVESTMENT_PAYOUT
                      }
                    >
                      <PrimaryButton
                        btype={'btn-warning btn-sm'}
                        title={t('payments.investment_payouts.retry.title')}
                        onClick={() => retryInvestmentPayout(cell)}
                      />
                    </PermissionAction>
                  </>
                )}
            </div>
          );
        },
      },
    ],
    [borrowerPayout?.status, confirmInvestmentPayout, retryInvestmentPayout, t],
  );

  const exportObsoleteInvestmentPayouts = useCallback(async () => {
    return Api.payments.borrowerPayouts
      .exportObsoleteInvestmentPayouts(borrowerPayoutId)
      .then((response) => {
        const blob = new Blob([response], {
          type: 'application/octet-stream',
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'investor_payouts_v1_import.zip';
        a.click();
      });
  }, [borrowerPayoutId]);

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('payments.borrower_payouts.investment_payouts')}</h3>
        </div>
        <div>
          <PermissionAction
            permissions={
              Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__EXPORT_OBSOLETE
            }
          >
            <PrimaryButton
              onClick={() => exportObsoleteInvestmentPayouts()}
              title={t('payments.borrower_payouts.export_obsolete_payouts')}
            />
          </PermissionAction>
        </div>
      </div>
      <hr />
      <HiddenColumnsProvider title={'InvestmentPayoutListIndex'}>
        <Table
          title={'InvestmentPayoutListIndex'}
          onFetchData={fetchData}
          columns={columns}
          data={data}
          searchable={true}
          enableQueryFilter={true}
          initialSortBy={INITIAL_SORT_ORDER}
          disableFiltersOutsideTable={true}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withBorrowerPayout(TabInvestmentPayouts);
